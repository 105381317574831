import { render, staticRenderFns } from "./NewsPost.vue?vue&type=template&id=594b9f21&scoped=true"
import script from "./NewsPost.vue?vue&type=script&lang=js"
export * from "./NewsPost.vue?vue&type=script&lang=js"
import style0 from "./NewsPost.vue?vue&type=style&index=0&id=594b9f21&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "594b9f21",
  null
  
)

export default component.exports