<template>
  <div>
    <button class="post" @click="openModal">
      <p class="title">
        {{ post.title }}
      </p>
      <span class="date">
        {{ date }}
      </span>
    </button>
    <Modal v-model="isModalOpen" :showAtBottom="true">
      <div class="content">
        <header>
          <span class="title">
            {{ post.title }}
          </span>
          <span class="date">
            {{ date }}
          </span>
        </header>
        <img v-if="imageUrl" :src="imageUrl" class="picture" />
        <section>
          <p class="description" v-html="renderMd(post.description)"></p>
          <b-button
            v-if="post.button"
            :href="post.button.url"
            variant="primary"
            class="button"
          >
            {{ post.button.label }}
          </b-button>
        </section>
      </div>
    </Modal>
  </div>
</template>

<script>
import format from "date-fns/format";
import MarkdownIt from "markdown-it";
import { resolveCmsMediaUrl } from "/utils/helpers.ts";
import { get } from "lodash";

export default {
  props: {
    post: { required: true },
  },

  data() {
    return {
      isModalOpen: false,
      title: "",
      description: "",
    };
  },

  components: {
    Modal: () => import("/components/Modal.vue"),
  },

  computed: {
    date() {
      return format(new Date(this.post.createdAt), "d.M.Y H:mm");
    },
    imageUrl() {
      const url = get(this.post, "picture.data.attributes.url");
      return url
        ? resolveCmsMediaUrl(this.post.picture.data.attributes.url)
        : null;
    },
  },

  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    renderMd(input) {
      return this.md.render(input);
    },
  },

  created() {
    this.md = new MarkdownIt({
      breaks: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.date {
  color: var(--a-color-dark-gray);
  font-family: Poppins;
  font-size: 0.875rem;
  line-height: 1.375rem;
}

.post {
  display: flex;
  justify-content: space-between;

  padding: 1rem;

  width: 100%;

  transition-duration: 0.2s;

  .title {
    color: inherit;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    max-width: 60%;

    text-align: left;
    font-family: Boing;
    font-weight: 800;
    line-height: 1.5rem;

    margin-bottom: 0;
  }

  &:hover {
    color: var(--a-color-blue);
    border-radius: 0.625rem;
    background: rgba(116, 122, 142, 0.1);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  width: 100%;

  header {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .title {
      margin-right: 2.5rem;

      color: var(--a-color-black);

      font-family: Boing;
      font-size: 1.5rem;
      line-height: normal;
    }
  }

  .picture {
    border-radius: 0.625rem;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 1.5rem;
    align-self: stretch;

    .description {
      align-self: stretch;
      margin-bottom: 0;

      > :last-child {
        margin-bottom: 0;
      }
    }

    .button {
      padding: 0.5rem 2rem;
    }
  }
}
</style>
